
import { computed, defineComponent, onMounted, ref } from 'vue';
import { userResources } from "@/api/users.api";
import { UserResource } from "@/types/User";
import DashBox from "@/components/cards/DashBox.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: 'Dashboard',
  components: {DashBox},
  setup() {
    let resources = ref<UserResource>({
      registered_user: -1,
      practices: -1,
      documents: -1,
      practice_closed: -1,
    });
    const store = useStore();

    async function getResources() {
      const response: any = await userResources();
      resources.value = response.data;
    }

    onMounted(() => {
      getResources();
    });

    return {
      resources,
      isAdmin: computed(() => store.getters.user.is_admin)
    }
  }
})
