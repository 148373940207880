<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 d-flex justify-content-between flex-nowrap mb-3">
        <DashBox
          :title="isAdmin ? 'Utenti registrati' : 'Pratiche chiuse'"
          :stat="isAdmin ? resources.registered_user : resources.practice_closed"
          :bootstrap-icon="isAdmin ? 'bi-people' : 'bi-folder-check'"
        />
      </div>
      <div class="col-md-4 d-flex justify-content-between flex-nowrap mb-3">
        <DashBox
          title="Documenti Caricati"
          :stat="resources.documents"
          bootstrap-icon="bi-file-check"
        />
      </div>
      <div class="col-md-4 d-flex justify-content-between flex-nowrap mb-3">
        <DashBox
          title="Pratiche Aperte"
          :stat="resources.practices"
          bootstrap-icon="bi-folder"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { userResources } from "@/api/users.api";
import { UserResource } from "@/types/User";
import DashBox from "@/components/cards/DashBox.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: 'Dashboard',
  components: {DashBox},
  setup() {
    let resources = ref<UserResource>({
      registered_user: -1,
      practices: -1,
      documents: -1,
      practice_closed: -1,
    });
    const store = useStore();

    async function getResources() {
      const response: any = await userResources();
      resources.value = response.data;
    }

    onMounted(() => {
      getResources();
    });

    return {
      resources,
      isAdmin: computed(() => store.getters.user.is_admin)
    }
  }
})
</script>

<style scoped>

</style>